<template>

<div class="container">
<div class="podprofil" style='margin-top: 110px;'>
    <h2>{{$t('filters')}}</h2>
    <hr class='align-self-sm-center align-self-md-start w-50 ml-0 mt-1 mb-3'>
    <div class="row justify-content-between">
        <div class="col-lg-7 col-md-12">
            <img src="/images/precistaci/precistac.png" alt="" class="slikeProfila" style="width: 85%;">
        </div>
        <div class="col-lg-5 col-md-12 mt-2 d-flex flex-column align-items-center">
            <p>
               {{$t('filters1')}}
            </p>
            <p >
                {{$t('filters2')}}
            </p>
        </div>
    </div>
</div>
</div>
    
</template>

<style>

</style>