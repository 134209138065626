export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
  "subprofiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subprofiles"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "fences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fences"])},
  "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the website of Interplastica d.o.o. Interplastica is a company based in Zenica that deals with advanced PVC extrusion solutions."])},
  "welcomeText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the site you can find information about our company, our way of working and the products we offer. For everything else, we invite you to contact us. "])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "carousel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced extrusion solutions"])},
  "carousel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience and innovation"])},
  "carousel2button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product development"])},
  "carousel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern equipment and educated personel"])},
  "carousel3button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tools"])},
  "card1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVC profiles occupy most of our production and are a perfect combination of quality and affordability."])},
  "card2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various options in the production of fences provide you with a product according to your specifications."])},
  "card3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to profiles and fences, Interplastica offers innovative solutions in the field of PVC purifiers."])},
  "icon1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meters of extruded"])},
  "icon1text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product a year"])},
  "icon2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tons of processed"])},
  "icon2text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plastic per year"])},
  "icon3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of products"])},
  "ourValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Values"])},
  "ourValuesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values that define our company follow every step of the production process."])},
  "knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge and experience"])},
  "machinePark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine park"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials we use"])},
  "wordMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
  "wordMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material"])},
  "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
  "thirtyYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 30 years of experience in the field of extrusion."])},
  "mostComplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most advanced solutions in extrusion. "])},
  "manyPartnerships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerous successful collaborations and partnerships around the world. "])},
  "machineParkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our machine park is filled with machines from eminent European equipment manufacturers in this area."])},
  "materialsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials which we use: "])},
  "materialsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVC, soft PVC, ABS, PP, HDPE, LDPE, TPE, WPVC..."])},
  "aboutUsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interplastica d.o.o Zenica was established in 2016. Although a relatively young company, Interplastica with its partner TD Tools has many years of experience in the field of extrusion. Interplastica is a combination of German knowledge and Bosnian industrial tradition and enthusiasm. "])},
  "mainActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Activity:"])},
  "processDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrusion process and product development "])},
  "serialService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic profile extrusion services"])},
  "coExtrusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coextrusion is a common process in our production with the use of up to 4 types of materials simultaneously."])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "toolsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 30 years of successful production of extrusion tools for customers around the world. Tool making relies on our strategic partner, TD Tools GmbH from Germany, which has 37 years of experience in extrusion tool making. Our partner offers solutions in this area, which includes jobs from tool construction to their manufacturing."])},
  "precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
  "eachTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each tool is designed in 3D-CAD systems to maintain the highest degree of tool precision in production and final adjustment."])},
  "european": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for window profiles - European type"])},
  "american": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for window profiles - American type"])},
  "wpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for WPC applications"])},
  "panelsAndFences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for panels and fences"])},
  "conduits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for conduits"])},
  "productDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Development"])},
  "developmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The path from the idea to the final product is often arduous and brings with it different requirements. Every customer wants a complete service for their product. This is our main advantage, as we provide a full service where we make the whole process of obtaining a new product more compact and comprehensive. For this reason, we offer a complete solution for your needs, starting from the idea to the final product. Throughout the development of the new product, we offer support in the design, construction and manufacture of tools as well as the selection of the appropriate materials. After obtaining the prototype pieces, they are transferred to serial production. We also offer optimized solutions and packaging variants as well as assistance in the logistics concept. Quality assurance follows the entire production process in all steps, all the way to logistics in order to deliver the product safely. "])},
  "developmentProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Process"])},
  "idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idea"])},
  "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
  "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling"])},
  "massProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass Production"])},
  "packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
  "logisticsConcept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics Concepts"])},
  "developmentProcessText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every customer request is a new challenge for us. It all starts with a good idea."])},
  "developmentProcessText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The design, construction and manufacture of tools is the foundation of any extruded product. In addition to the product, in this part the concept of the whole process of the new product is created. If necessary, we make a 3D prototype of the product."])},
  "developmentProcessText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By obtaining the first pieces, we perform internal and external testing, which includes dimensional and functional control. In the end, as a confirmation, we receive approval from the customer that we can go into serial production of the product. "])},
  "developmentProcessText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every minute, new meters of the perfect product are made, continuously from hour to hour (24/7). "])},
  "developmentProcessText5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact, safe and optimized product packaging solutions. "])},
  "developmentProcessText6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comprehensive and harmonized logistics concept completes the new product development process."])},
  "filters1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A complete biological solution for municipal and commercial wastewater treatment. Modular design, flexible upgradeability and long-term reliability are just some of the features of the purifier. "])},
  "filters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapting to individual requirements means that the system is a uniquely designed project, it is also possible to expand or upgrade existing installations. The key to the high efficiency of these purifiers is the design that provides a large biologically active surface. "])},
  "universalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universal PVC profile for sills "])},
  "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility, with connection to aluminum sill. Universal application. "])},
  "reciklat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycled PVC window profiles"])},
  "universal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universal"])},
  "windowUniversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Window universal molding"])},
  "distancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacers for logistics solutions (packaging) "])},
  "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrications with different dimensional characteristics are possible "])},
  "compatibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatibility"])},
  "possibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibilites of production"])},
  "possibilities2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibility of production with different degree of hardness (55-90 Shore) as well as different processing after extrusion."])},
  "softPvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made of plasticized soft PVC material. "])},
  "windowSubprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Window subprofile"])},
  "blueEvoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double seal. Optional without additional connection to the window profile. Possible universal application. "])},
  "bsvText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility, double seal, foam-filled outer chamber, optionally foam-free, and without bench connection. Possible universal application. "])},
  "pegText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility, double seal, foam-filled outer chamber, optionally foam-free, and without bench connection. Possible universal application. "])},
  "salamanderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility, sealing, Profile width 20 to 30mm, maximum 6 chambers. Possible universal application."])},
  "fencesCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fence Characteristics"])},
  "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
  "warrantyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With all our fences, you get a 10 year warranty "])},
  "UVStability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UV Stability"])},
  "UVStabilityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantee of UV stability of all fences "])},
  "assembly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembly"])},
  "assemblyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation on concrete and earthen substrates "])},
  "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
  "dimensionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel width and height available in different dimensions "])},
  "availableColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Colors"])},
  "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
  "panelDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Dimensions"])},
  "basicDimensionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["174cm(width) x 180cm(height)"])},
  "basicAssemblyParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation parts:"])},
  "basicAssemblyPartsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame – 2 pcs"])},
  "basicAssemblyPartsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filling– 12 pcs"])},
  "basicAssemblyPartsText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinforcement (metal)"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "postDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Dimensions"])},
  "postDimensionsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Square cross section-10x10cm"])},
  "postDimensionsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wall thickness-3,50mm"])},
  "assemblyPartsOnGround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earthen surface mounting parts:"])},
  "screws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screws"])},
  "caps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caps"])},
  "capsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic, Inox(Straight), Inox(Pyramid)"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["width"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["height"])},
  "basicAssemblyPartsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame - 3 pcs"])},
  "basicAssemblyPartsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filling - 12 pcs"])},
  "basicAssemblyPartsText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L profile - 2 pcs"])},
  "reinforcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinforcement"])},
  "partsForMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concrete mounting parts:"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
  "ankerScrews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anker screws"])},
  "foldedPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folded Panel"])},
  "hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanger"])},
  "hangers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangers"])},
  "additionalPossibilitesForMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional possibilities for mounting:"])},
  "decorationalMolding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decorational molding on post"])},
  "extraReinforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Reinforcement"])}
}