<template>
    <div class="jumbotron jumbotron-fluid shadow-sm border-0" id="contactus">
    <h1 class="display-4 naslov d-flex justify-content-center my-3 bounce-in-top">{{$t('contactUs')}}</h1>

</div>
<div class="container">



    <div class="d-md-flex justify-content-around d-sm-block text-center mb-5">

        <p class="text-muted">INTERPLASTICA <br>
            D.O.O. ZENICA </p>
        <div class="d-flex flex-column flex-md-row">
            <i class="fas fa-map-marker float-left mt-md-3 mr-md-3 d-inline d-md-block  "></i>
            <p class="text-muted float-right ">Ul. Zmaja od Bosne bb <br>
                72000 Zenica Bosna i Hercegovina</p>
        </div>

        <div class="d-flex flex-column flex-md-row">
            <i class="fas fa-phone float-left mt-3 mr-2 d-inline d-md-block"></i>
            <p class="text-muted float-right">+387 32 201 440 <br>
                +387 32 201 441 </p>
        </div>
        <p class="text-muted mt-2"><i class="far fa-envelope mr-2 d-inline d-md-inline"></i>info@interplastica.ba</p>
    </div>
  

    <div class="text-center">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11439.669322617876!2d17.9005775!3d44.208769!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x21a5ec0f8700b054!2sInterplastica+doo+Zenica!5e0!3m2!1sen!2sno!4v1563364873279!5m2!1sen!2sno" width="80%" height="300" frameborder="0" style="border:0" allowfullscreen ></iframe>

    </div>

</div>
</template>


<style >
#contactus {
    /* background-image: url("");
    background-repeat: no-repeat;
    background-position: center;    
    background-size: cover; */
    color: black;
    margin-top: 70px;
    background-color: rgba(242,242,242, 0.8);

}

</style>