export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslovna"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodi"])},
  "profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili"])},
  "subprofiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podprofili"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečistači"])},
  "fences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograde"])},
  "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvoj"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli"])},
  "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli na web stranicu Interplastica d.o.o. Interplastica je firma bazirana u Zenici koja se bavi naprednim rješenjima PVC ekstruzije."])},
  "welcomeText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stranici možete naći informacije o firmi, našem načinu rada te proizvodima koje nudimo. Za sve ostalo, pozivamo vas da nas kontaktirate."])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas"])},
  "carousel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredna rješenja u ekstruziji"])},
  "carousel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskustvo i inovativnost"])},
  "carousel2button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvoj proizoda"])},
  "carousel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savremena oprema i obrazovani kadar"])},
  "carousel3button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naši alati"])},
  "card1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVC profili zauzimaju većinu naše proizvodnje te su savršen spoj kvalitete i pristupačnosti."])},
  "card2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razne opcije u proizvodnji ograda osiguravaju vam proizvod po vašim specifikacijama."])},
  "card3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pored profila i ograda, Interplastica nudi inovativna rješenja u sferi PVC prečistača."])},
  "icon1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metara ekstrudiranih"])},
  "icon1text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proizvoda godišnje"])},
  "icon2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tona prerađene plastične"])},
  "icon2text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mase godišnje"])},
  "icon3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj proizvoda"])},
  "ourValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše Vrijednosti"])},
  "ourValuesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijednosti koje definisu našu kompaniju prate svaki korak proizvodnog procesa."])},
  "knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znanje i iskustvo"])},
  "machinePark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mašinski park"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijali koje koristimo"])},
  "wordMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijali"])},
  "wordMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijal"])},
  "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije"])},
  "thirtyYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preko 30 godina iskustva u oblasti ekstruzije."])},
  "mostComplex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najzahtjevnija rješenja u ekstruziji."])},
  "manyPartnerships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnogobrojne uspjesne saradnje i partnerstva sirom svijeta."])},
  "machineParkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mašinski park je popunjen mašinama eminentnih evropskih proizvođača opreme u ovoj oblasti."])},
  "materialsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijali sa kojim se susrećemo su: "])},
  "materialsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVC, meki PVC, ABS, PP, HDPE, LDPE, TPE, WPVC..."])},
  "aboutUsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interplastica d.o.o Zenica je nastala 2016. godine. Iako relativno mlada firma, Interplastica sa svojim partnerom TD Tools ima višegodišnje iskustvo u oblasti ekstruzije. Interplastica predstavlja spoj njemačkog znanja i bosanskohercegovačke industrijske tradicije i entuzijazma."])},
  "mainActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osnovna Djelatnost:"])},
  "processDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvoj procesa i proizvoda u ekstruziji"])},
  "serialService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluga serijskog ekstrudiranja plastičnih profila"])},
  "coExtrusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koekstruzija je uobičajen proces u našoj proizvodnji sa korištenjem i do 4 vrste materijala istovremeno."])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri"])},
  "toolsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Više od 30 godina uspješne proizvodnje alata za ekstruziju za klijente širom svijeta. Izrada alata je naslonjena na našeg strateškog partnera, firmu TD Tools GmbH iz Njemačke, koja ima 37 godina iskustva u izradi alata za ekstruziju. Naš partner nudi rješenja iz ove oblasti što podrazumijeva poslove od konstrukcije alata do njihove izrade."])},
  "precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preciznost"])},
  "eachTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svaki alat je dizajniran u 3D-CAD sistemima da bi se održao najviši stepen preciznosti alata u proizvodnji i finalnom podešavanju."])},
  "european": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati za profile prozora - Europski tip"])},
  "american": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati za profile prozora - Američki tip"])},
  "wpc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati za WPC aplikacije"])},
  "panelsAndFences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati za panele i ograde"])},
  "conduits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati za kanalice"])},
  "productDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvoj Proizvoda"])},
  "developmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put od ideje do finalnog proizvoda često biva naporan te nosi sa sobom različite zahtjeve. Svaki kupac želi kompletnu uslugu za svoj proizvod. Upravo u tome mi vidimo svoju prednost jer ćemo potpunom uslugom rasteretiti kupca a cjelokupni proces dobijanja novog proizvoda učiniti kompaktnim i sveobuhvatnijm. Iz tog razoga, nudimo cjelokupno rješenje za Vaš proizvod, počevši od same ideje do konačnog proizvoda. Tokom cijelog razvoja novog proizvoda nudimo podršku pri dizajnu, konstrukciji i izradi alata kao i odabir odgovarajućeg materijala. Nakon dobijanja prototipnih komada prelazi se u serijsku proizvodnju. Također, nudimo optimizirana rješenja i varijante pakovanja kao i pomoć u logističkom konceptu. Osiguranje kvaliteta prati cijeli proizvodni proces u svim koracima, pa tako sve do logistike kako bi se na siguran način dopremio proizvod."])},
  "developmentProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tok Razvoja"])},
  "idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideja"])},
  "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajn"])},
  "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzorčenje"])},
  "massProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serijska Proizvodnja"])},
  "packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakovanje"])},
  "logisticsConcept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistički Koncept"])},
  "developmentProcessText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svaki zahtjev od kupca je za nas novi izazov. Sve počinje sa dobrom idejom."])},
  "developmentProcessText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajn, konstrukcija i izrada alata je temelj svakog ekstrudiranog proizvoda. Osim proizvoda u ovom dijelu nastaje koncept cijelog procesa novog proizvoda. Po potrebi izrađujemo 3D prototip proizvoda. "])},
  "developmentProcessText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobijanjem prvih komada vršimo interna i eksterno testiranja što podrazumijeva dimenzijsku i funkcionalnu kontrolu. Na kraju kao potvrdu dobijamo odobrenje od kupca da se može ići u serijsku proizvodnju proizvoda."])},
  "developmentProcessText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svakim minutom se dobijaju novi metri perfektnog proizvoda i tako kontinuirano iz sata u sat (24/7). "])},
  "developmentProcessText5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaktna, sigurna i optimizirana rješenja pakovanja proizvoda."])},
  "developmentProcessText6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveobuhvatan i usklađen logistički koncept zaokružuje kompletan proces razvoja novog proizvoda."])},
  "filters1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potpuno biološko rješenje za pročišćavanje komunalnih i komercijalnih otpadnih voda. Modularni dizajn, fleksibilna nadogradivost i dugoročna pouzdanost su samo neke odlike prečistaća."])},
  "filters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prilagođavanje individualnim zahtjevima znači da je sistem jedinstveno dizajniran projekt, također je moguće proširiti ili nadograditi postojeće instalacije. Ključ visoke učinkovitosti ovih prešistaća je dizajn koji osiguravaju veliku biološku aktivnu površinu."])},
  "universalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Univerzalni PVC profil za pragove"])},
  "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilnost, sa priključkom na aluminijski prag. Moguća univerzalna primjena."])},
  "reciklat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciklat PVC prozorskih profila"])},
  "universal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Univerzalno"])},
  "windowUniversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozorska univerzalna lajsna"])},
  "distancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanceri za logistička rješenja (pakovanje)"])},
  "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moguće su izrade sa različitim dimenzijskim karakteristikama"])},
  "compatibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompatibilnost"])},
  "possibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnosti izrade"])},
  "possibilities2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost izrade sa različitim stepenom tvrdoće (55-90 Shore) kao i različitim obradama nakon ekstruzije."])},
  "softPvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izrađuju se od plastificiranog mekog PVC materijala."])},
  "windowSubprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozorski podprofil"])},
  "blueEvoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupli dihtung. Opcionalno bez dodatnog priključka na prozorski profil. Moguća univerzalna primjena"])},
  "bsvText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilnost, dupli dihtung, vanjska komora ispunjena pjenom, opcionalno dostupno bez pjene, i bez priključka za klupicu. Moguća univerzalna primjena."])},
  "pegText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilnost, dupli dihtung, vanjska komora ispunjena pjenom, opcionalno dostupno bez pjene, i bez priključka za klupicu. Moguća univerzalna primjena."])},
  "salamanderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilnost, dihtung, Širina profila 20 do 30mm, maksimalno 6 komora. Moguća univerzalna primjena."])},
  "fencesCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karakteristike Ograda"])},
  "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garancija"])},
  "warrantyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz sve nase ograde, dobijate 10 godina garancije"])},
  "UVStability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UV Stabilnost"])},
  "UVStabilityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantovanost UV stabilnosti svih ograda"])},
  "assembly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaža"])},
  "assemblyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaža na betonskoj i zemljanoj podlozi"])},
  "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije"])},
  "dimensionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Širina i visina panela dostupna u različitim dimenzijama"])},
  "availableColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupne boje"])},
  "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])},
  "panelDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije panela"])},
  "basicDimensionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["174cm(širina) x 180cm(visina)"])},
  "basicAssemblyParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijelovi za montažu:"])},
  "basicAssemblyPartsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okvir – 2 kom"])},
  "basicAssemblyPartsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispuna – 12 kom"])},
  "basicAssemblyPartsText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ojačanje (metal)"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stub"])},
  "postDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije stuba"])},
  "postDimensionsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvadratni presjek-10x10cm"])},
  "postDimensionsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debljina stijenke-3,50mm"])},
  "assemblyPartsOnGround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijelovi za montažu na zemljanoj podlozi:"])},
  "screws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vijci"])},
  "caps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kape"])},
  "capsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasticna, Inox(Ravna), Inox(Piramida)"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["širina"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visina"])},
  "basicAssemblyPartsText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okvir – 3 kom"])},
  "basicAssemblyPartsText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispuna – 12 kom"])},
  "basicAssemblyPartsText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L profil - 2 kom"])},
  "reinforcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ojačanje"])},
  "partsForMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijelovi za montažu na betonskoj podlozi:"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa"])},
  "ankerScrews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anker vijci"])},
  "foldedPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklopljeni panel"])},
  "hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakačka"])},
  "hangers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakačke"])},
  "additionalPossibilitesForMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne mogućnosti ugradnje:"])},
  "decorationalMolding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrasna lajsna na stubu"])},
  "extraReinforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatna ojačanja"])}
}