<template>
<div class="container">
<div class='d-flex justify-content-around mb-5 row ' style="margin-top: 120px;">
    <div class=" align-self-center text-left col-md-6 col-sm-12 d-flex flex-column">
      <h1 class='align-self-sm-center align-self-md-start'>{{$t('tools')}}</h1>
      <hr class='align-self-sm-center align-self-md-start w-50 ml-0 mt-1 mb-3'>
      <p style="text-align: justify;">
        {{$t('toolsDesc')}}
      </p>
    </div>

    <div class='col-sm-12 col-md-6 d-flex'>
      <img class=' align-self-center img-fluid rounded shadow-sm' src="/images/alat.png" alt="">
    </div>
  </div>
</div>



<div class="alatBar d-flex flex-column align-items-center justify-content-center">
    <div class="container d-flex flex-column">
<h1 class="align-self-md-start">{{$t('precision')}}</h1>
<hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
<p>
{{$t('eachTools')}}
</p>
<img style="width: 500px;" class=" img-fluid mt-3 align-self-start" src="/images/trialata.png" alt="">
</div>
</div>


<div class="container">


  <div class='d-flex flex-column'>
    <h1 class='align-self-start mt-5' >{{$t('products')}}</h1>
    <h3 class='mt-4'>{{$t('european')}}</h3>
    <hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
    <img class='img-fluid  align-self-start' src="/images/alati/drugatrialata.png" alt="">

  </div>

  <div class=' mt-5 d-flex flex-column '>
      <h3 class='mt-4'>{{$t('american')}}</h3>
      <hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
      <img class='img-fluid  align-self-start' src="/images/alati/dvaalata.png" alt="">
  
    </div>

    <div class='mt-5 d-flex flex-column '>
        <h3 class='mt-4'>{{$t('wpc')}}</h3>
        <hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
        <img class='img-fluid  align-self-start' src="/images/alati/drugadvaalata.png" alt="">
    
      </div>

      <div class='mt-5 d-flex flex-column '>
          <h3 class='mt-4'>{{$t('panelsAndFences')}}</h3>
          <hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
          <img class='img-fluid  align-self-start' src="/images/alati/trecadvaalata.png" alt="">
      
        </div>


        
      <div class='mt-5 d-flex flex-column '>
          <h3 class='mt-4'>{{$t('conduits')}}</h3>
          <hr class=' align-self-md-center w-50 ml-0 mt-1 mb-3'>
          <img class='img-fluid  align-self-start' src="/images/alati/cetvrtadvaalata.png" alt="">
      
        </div>


</div>
    
</template>
<style >

</style>