<template>
<a href="#" id="scroll" style="display: none;"><span></span></a>
<!-- Footer -->

    <div id="plavaLinija" class="mt-5"></div>
    <footer class=" bg-light border-top pb-3">
        <div class="container text-center col-md-2">
            <img class="img-fluid img-responsive my-2" src="/images/logo.png" alt="">
        </div>
        <div style="margin-bottom: 33px;" class="d-md-flex justify-content-around d-sm-block text-center">

            <p class="text-muted">INTERPLASTICA <br>
                D.O.O. ZENICA </p>
            <div class="d-flex flex-column flex-md-row">
                <i class="fas fa-map-marker float-left mt-md-3 mr-md-3 d-none d-md-block  "></i>
                <p class="text-muted float-right ">Ul. Zmaja od Bosne bb <br>
                    72000 Zenica Bosna i Hercegovina</p>
            </div>

            <div class="d-flex flex-column flex-md-row">
                <i class="fas fa-phone float-left mt-3 mr-2 d-none d-md-block"></i>
                <p class="text-muted float-right">+387 32 201 440 <br>
                    +387 32 201 441 </p>
            </div>
            <p class="text-muted mt-2"><i class="far fa-envelope mr-2 d-none d-md-inline"></i>info@interplastica.ba</p>
        </div>
    </footer>
</template>


